$(document).ready(function () {
    let menuBar = $(".actv");
    let menuBlock = $(".menu-block");
    $("#fullpage").fullpage({
        anchors: ["algoriteam", "about", "contact"],
        menu: "#menu",
        slidesNavigation: true,
        easing: "easeInOutQuad",
        scrollingSpeed: 1000,
        css3: true,
        responsiveWidth: 991,

        onLeave: function (anchor, index) {
            if (index == 1) {
                menuBar.removeClass("actv-about");
                menuBar.removeClass("actv-contact");
            }
            if (index == 2) {
                menuBar.addClass("actv-about");
                menuBar.removeClass("actv-contact");
            }
            if (index == 3) {
                menuBar.addClass("actv-contact");
            }
        }
    });
    anime({
        targets: ".home-unicorn",
        translateY: [1100, 0],
        delay: 1000,
        duration: 3500,
        easing: "easeOutExpo"
    });

    anime({
        targets: ".menu-block",
        translateX: [1100, 0],
        delay: 2000,
        duration: 3500,
        easing: "easeOutExpo"
    });

    anime({
        targets: ".lang",
        translateY: [1100, 0],
        delay: 2500,
        duration: 3500,
        easing: "easeOutExpo"
    });

    anime({
        targets: ".partical",
        translateY: [1000, 0],
        scale: [0.1, 1],
        delay: 1000,
        duration: 5000,
        easing: "easeOutExpo"
    });

    anime({
        targets: ".icon path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 1000,
        delay: function (el, i) {
            return i * 100;
        },
        direction: "alternate",
        loop: false
    });

    anime({
        targets: ".icon path",
        fill: ["transparent", "#555D84"],
        duration: 1000,
        delay: 2100
    });

    anime({
        targets: ".icon",
        duration: 1000,
        delay: 2100,
        scale: [0.8, 1],
        easing: "spring(1, 80, 10, 0)"
    });


    $(".nav").click(function () {
        $(this).toggleClass("active");
        menuBlock.toggleClass("open");
        $(".lang").toggleClass("open-lang");
        $('meta[name="theme-color"]').attr(
            "content",
            menuBlock.hasClass("open") ? "#555D84" : ""
        );
    });

    $(".menu-link").click(function () {
        setTimeout(function () {
            menuBlock.removeClass("open");
            $(".lang").removeClass("open-lang");
            $(".menu").toggleClass("active");
            $('meta[name="theme-color"]').attr(
                "content",
                menuBlock.hasClass("open") ? "#555D84" : ""
            );
        }, 300);
    });

    $(".contact-action").click(function () {
        $(".contact-form").addClass("active");
        $(".app").addClass("open-form");
        $("html, body").animate({scrollTop: $(document).height()}, 1000);
    });

    $(".form-close-btn").click(function (e) {
        $(".contact-form").removeClass("active");
        $(".app").removeClass("open-form");
        e.stopPropagation();
    });
});

